.modal-backdrop {
    width: 105vw !important;
}

.embedEntityModal.markdown img {
    display: block;
    max-width: 600px;
}

.embedEntityModal .modal-body {
    overflow: scroll;
}

.embedEntityModal .modal-body .react-bootstrap-table {
    overflow-x: visible;
}

@media (min-width: 992px) {
    .exampleModal {
     transform: translate(20vw, 10px) !important;
    }
    .embedEntityModal .modal-content {
        max-height: 600px;
    }
}

.tag-editor {
    display: flex;
    flex-wrap: wrap;
}

.tag-editor div.tag-input {
    flex-grow: 1;
    border: 0;
    margin-left: 5px;
}

.tag-editor .tag {
    display: flex;
    align-items: center;
    padding-left: 5px;
    background-color: grey;
    color: white;
    border-radius: 0.25rem;
    margin-left: 5px;
}

.tag-editor .tag button {
    margin-left: 5px;
    border: 0;
    background: none;
    color: white;
}

.tag-editor-container {
    height: auto;
}

