@media (min-width: 992px) {
    .vertical-menu, .navbar-brand-box  {
        width: 215px !important
    }
    .main-content {
        margin-left: 215px !important
    }
    .footer {
        left: 215px !important
    }
}

@media (max-width: 991px) {
    .vertical-menu {
        // width: 60vw;
    }
}