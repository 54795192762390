.f-12 {
    font-size: 12px;
}

.f-14 {
    font-size: 14px;
}

.f-16 {
    font-size: 16px;
}

.f-20 {
    font-size: 20px;
}

.f-24 {
    font-size: 24px;
}

.f-8 {
    font-size: 8px;
}

.f-10 {
    font-size: 10px;
}

.f-11 {
    font-size: 11px;
}

.f-18 {
    font-size: 18px;
}

.f-25 {
    font-size: 25px;
}