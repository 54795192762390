.ecosystem-suggestions {
    background: #2a3042;    
    position: absolute;
    top: 36px;
    left: 0;
    z-index: 10;
    list-style: none;
    overflow: scroll;
    max-height: 90vh;
    min-width: 100%;
    padding-left: 0px;
    &-product {
        top: 45;
        background: white;
        border: 1px solid lightgray;
        left: 0;
        z-index: 10;
        list-style: none;
        overflow: scroll;
        max-height: 50vh;
        min-width: 100%;
        border-radius: 5px;
        padding-left: 0px;
        li {
            padding: 10px;
            border-bottom: 1px solid lightgray;
            p {
                color: #a6b0cf;
            }
        }
    }
    &-title {
        color: #a6b0cf;
    }
    li {
        padding-top: 20px;
        border-bottom: 1px solid lightgray;
        p {
            color: #a6b0cf;
        }
        a > i {
            color: #a6b0cf;
        }
        &:hover {
            background: #394159;
            cursor: pointer;
            a > i {
                color: white;
            }
            a > div > p {
                color: white;
            }
        }
    }

}