@import 'ecosystem-login.scss';
@import 'ecosystem-spinner.scss';
@import 'ecosystem-seperate-input';
@import 'ecosystem-font-sizes';
@import 'ecosystem-responsive';
@import 'ecosystem-suggestions';
@import 'ecosystem-form';
@import 'ecosystem-tables';
@import 'ecosystem-modal';
@import 'ecosystem-dashboard';
@import 'ecosystem-buttons';


#layout-wrapper > div.main-content > div > div > div:nth-child(5) > div > div.collapse.show > div > div.mt-5.mb-5.col-lg-12 > div.row.form-group > div > div > div.select2-selection.col-3__menu.css-26l3qy-menu
{ z-index: 999;}

.ecosystem-column-toggler-dropdown {
  position: absolute;
  z-index: 999;
  min-width: 150px;
  margin-top: 35px;
  @media (max-width: 992px) {
    width: 100%
  }
}


.carousel-control-prev-icon,
.carousel-control-next-icon,
.carousel-indicators {
  filter: invert(1)
}

.css-tlfecz-indicatorContainer {
  z-index: 999;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.ecosystem-hover {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}


.profile-user-wid {
  margin-top: -55px !important;
}

.main-content {
  // background: url('../images/LoginBackground.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top;
  background-attachment: fixed;
}

.metismenu  {
  li {
    padding: 5px 0px;
  }
}

.ecosystem-alerts {
    position: fixed;
    top: 80px;
    z-index: 100;
    width: 100%;
}

.center-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.badge-gray {
    background-color: $gray-400;
}

.img-fluid {
    width: 100%;
    height: auto;
}

.footer {
  bottom: 0;
  padding: 11px calc(24px / 2);
  position: fixed;
  right: 0;
  color: #74788d;
  left: 200px;
  height: 40px;
  background-color: transparent;
  font-size: 10px;
  @media (max-width: 992px) {
    left: 0;
    position: absolute;
  }
}
.footer-non-auth {
  bottom: 0;
  padding: 11px calc(24px / 2);
  position: fixed;
  right: 0;
  color: #74788d;
  left: 0;
  height: 40px;
  background-color: #ffffff11 !important;
  font-size: 10px;
  @media (max-width: 992px) {
    left: 0;
    position: absolute;
  }
}

.progress {
  background-color: #ece6e6 !important;
}

.topnav .navbar-nav .nav-link:focus, .topnav .navbar-nav .nav-link:hover {
  color: #1182ae !important
}

.mm-active, .mm-active > i , .mm-active .active {
    color: #1182ae !important
}


/* Hide scrollbar for Chrome, Safari and Opera */
#sidebar-menu::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  menu{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .ecosystem-header {
    background-color: 'white' !important;
    border-bottom: '2px solid #f6f6f6' !important;
}

.list-logo {
  max-width: 120px;
}

.ecosystem-list {
  padding-left: 0px;
  list-style: none;
}

body[data-layout="horizontal"][data-topbar="light"] .topnav {
  background-color: #35c28f !important
}

body[data-sidebar="colored"] .vertical-menu, body[data-sidebar="colored"] .navbar-brand-box {
  background-color: #35c28f!important;
}

body[data-sidebar="colored"] #sidebar-menu ul li ul.sub-menu li a, body[data-sidebar="colored"] #sidebar-menu ul li a  {
  color: #fdfcfc !important;

}


body[data-layout="horizontal"][data-topbar="colored"] #page-topbar {
  background-color: #35c28f !important
}

body[data-layout="horizontal"][data-topbar="colored"] .topnav {
  background-color: rgba(52, 195, 143, 0.25) !important;
  color: black;
  .navbar-nav .nav-link {
    color: black !important;
}
}

body[data-sidebar="dark"] .navbar-brand-box {
  height: 63px !important
}

#sidebar-menu {
overflow: scroll;
height: 100vh;
}
// @keyframes sizing {
//   0%   {width: 9%;}
//   50%  {width: 10%;}
//   100% {width: 9%;}
// }
// @keyframes xmove1 {
//   0%   {transform: translate(45px, 340px);}
//   50%  {transform: translate(45px, 310px);}
//   100% {transform: translate(45px, 340px);}
// }
// @keyframes xmove2 {
//   0%   {transform: translate(180px, 410px);}
//   50%  {transform: translate(180px, 370px);}
//   100% {transform: translate(180px, 410px);}
// }
// @keyframes ymove1 {
//   0%   {transform: translate(-10px, 430px);}
//   50%  {transform: translate(-20px, 430px);}
//   100% {transform: translate(-10px, 430px);}
// }
// @keyframes ymove2 {
//   0%   {transform: translate(70px, 480px);}
//   50%  {transform: translate(60px, 480px);}
//   100% {transform: translate(70px, 480px);}
// }
// .sizing{
//   animation-name: sizing;
//   animation-duration: 3s;
//   animation-iteration-count: infinite;
// }
// .xmove1 {
//   animation-name: xmove1;
//   animation-duration: 5s;
//   animation-iteration-count: infinite;
// }
// .xmove2 {
//   animation-name: xmove2;
//   animation-duration: 5s;
//   animation-iteration-count: infinite;
// }
// .ymove1 {
//   animation-name: ymove1;
//   animation-duration: 5s;
//   animation-iteration-count: infinite;
// }
// .ymove2 {
//   animation-name: ymove2;
//   animation-duration: 5s;
//   animation-iteration-count: infinite;
// }

.page-break {
  page-break-after: always;
  page-break-inside: avoid;
  clear:both;
}
.page-break-before {
  page-break-before: always;
  page-break-inside: avoid;
  clear:both;
}
#html-2-pdfwrapper{
  position: absolute; 
  left: 20px; 
  top: 50px; 
  bottom: 0; 
  overflow: auto; 
  width: 600px;
}


.checkout-tabs .nav-pills .nav-link.active {
  background-color: #34c38f4f;
}

.css-26l3qy-menu {
  z-index: 100 !important;
  background-color: white !important;
}

