#pageDropDown, .page-item.active .page-link {
    background-color: transparent !important;
    color: gray !important;
    border: 1px solid gray;
}

.react-bootstrap-table-page-btns-ul {
    justify-content: flex-end !important;
}

.react-bootstrap-table table {
    table-layout: auto;
  }

.base-line {
    display: "flex";
    align-items: "baseline";
}

.without-border th, .without-border td {
    border-top: hidden !important;
    padding: 8px !important
}

.responsiveTable tbody tr {
    padding: .25em;
    border-top: 1px solid #eff2f7;
    td, td.pivoted, th {
        border: none !important;
        position: relative;
        padding-left: 0px !important;
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }
    @media (max-width: 991px) {
        border: 'none' !important;
        border-top: 1px solid #eff2f7;
    }
    @media screen and (max-width: 40em) {
        border-left: 1px solid white !important;
        border-right: 1px solid white !important;
        border-bottom: 1px solid white !important;
        border-top: 1px solid #eff2f7;
        padding: .25em;
    }
}

.badge-purple {
    background-color: rgb(238, 240, 254);
}

.badge-purple-dark {
    background-color: rgb(182, 187, 228);
}

.ecosystem-select-columns-dropdown {
    position: 'absolute';
    top : '40px';
    z-index: 999;
}

.table-condensed > thead > tr > th, .table-condensed > tbody > tr > td {
    cursor: pointer !important;
}


