.white-background {
    background: "white" !important;
    height: "100vh";
}

.ecosystem-login-container {
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../images/LoginBackground.png);
    height: 100vh;
}

@media (min-width: 992px) {
    .login-image {
        position: absolute;
        right: -70px;
        z-index: 2;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 80%
    }
}


.login-form {
    position: relative;
    z-index: 10;
    margin-top: 100px;
    @media (min-width: 992px) {
        margin-left: 50px;
    }
}

.styles_react-code-input-container__tpiKG {
    display: flex !important;
    width: 100% !important;
    justify-content: space-evenly !important;
}
