.ecosystem-dasboard-box-links {
    border-radius: 5px;
    box-shadow: 0 1rem 2rem 0 rgba(208,223,232,.25);
    height: 80px;
    &:hover {
        cursor: pointer;
    }
}

.ecosystem-dashboard-box-shadow {
    box-shadow: 0 1rem 2rem 0 rgba(208,223,232,.35);
    background-color: #fff;
}

.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon,
.apexcharts-zoom-icon,
.apexcharts-selected,
.apexcharts-pan-icon,
.apexcharts-reset-icon
 {
  display: none
}

.ecosystem-dashboard-box {
    background-color: #f8f9fa;
}

.ecosystem-header {
    background-color: 'white' !important;
    border-bottom: '2px solid #f6f6f6' !important;
}